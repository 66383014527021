export enum NpsEnum {
  APP = 1,
  ONE_CLICK_DEAL_ADM = 2,
  CONTRACT_MANAGEMENT = 3,
  MARKET_INTELLIGENCE_BRAZIL = 4,
  MARKET_INTELLIGENCE_PARAGUAY = 5,
  EASY_BARTER_ADM = 6,
  ONE_CLICK_DEAL_LDC = 7,
  ONE_CLICK_DEAL_CARGILL = 8,
  ONE_CLICK_DEAL_AMAGGI = 9,
  EASY_BARTER_BASF = 10,
  EASY_BARTER_BAYER = 11,
  GDVIP = 12,
  CF_PRODUCTOR = 13,
  EASY_BARTER_CIMOAGRO = 14,
  EASY_BARTER_PETROVINA = 15,
  EASY_BARTER_SLC = 16,
  EASY_BARTER_CONECTA = 17,
  EASY_BARTER_AMAGGI = 18,
  CLIENT_MANAGEMENT_ADM = 19,
  MARKET_INTELLIGENCE_CORPORATE = 20,
  EASY_BARTER_RIO_BRANCO = 22,
  EASY_BARTER_HINOVE = 23,
}
